<template>
    <div>
        <div class="text-center mt-4">
            <h1 class="h3">
                Clientes en total
                <strong class="text-primary">
                    {{ itemsNumber }}
                </strong>
            </h1>
        </div>

        <div class="d-flex flex-wrap mt-2">
            <div class="col-12 my-3">
                <customers-filters v-model="query" @enter="reloadItems" />
                <div class="d-flex justify-content-center">
                    <vs-button v-show="query" relief @click="reloadItems">
                        Consultar 🔎
                    </vs-button>
                </div>
            </div>
            <div
                class="d-flex justify-content-between flex-column flex-lg-row col-12 my-3"
            >
                <div class="d-flex align-items-center justify-content-around">
                    <tooltip-button
                        v-show="selected.length"
                        icon="🧹"
                        message="Limpiar selección"
                        @click="clearSelected"
                    />
                </div>

                <div class="d-flex align-items-center justify-content-around">
                    <vs-pagination
                        v-model="currentPage"
                        :length="pagesNumber"
                        @input="listItems()"
                    />
                    <tooltip-redirect
                        v-if="$ability.hasScope('customer:Create')"
                        to="/app/customers/new"
                        message="Registrar cliente"
                    >
                    </tooltip-redirect>
                </div>
            </div>
        </div>

        <div v-show="showTable" class="d-flex flex-wrap mt-4">
            <b-table
                ref="selectableTable"
                :fields="$data.$fields"
                :items="itemsCurrent"
                :dark="activeDark"
                :per-page="itemsCurrent.length"
                select-mode="multi"
                responsive
                outlined
                no-border-collapse
                hover
                selectable
                class="col-12"
                @row-selected="onRowSelected"
            >
                <template #cell(id)="data">
                    <div class="d-flex align-items-center">
                        <router-link
                            :to="`/app/customers/detail/${data.item.id}`"
                            class="text-center font-weight-normal btn btn-primary p-1"
                        >
                            <small>
                                {{ data.item.id | cut(11) }}
                            </small>
                        </router-link>
                    </div>
                </template>
                <template #cell(identificationNumber)="data">
                    <small
                        class="text-center font-weight-normal text-uppercase"
                    >
                        {{ data.item.identificationType }}
                        {{ data.item.identificationNumber || "NA" }}
                    </small>
                </template>
                <template #cell(firstName)="data">
                    <small
                        class="text-center font-weight-normal text-capitalize"
                    >
                        {{ data.item.firstName }} {{ data.item.lastName }}
                    </small>
                </template>
                <template #cell(email)="data">
                    <small class="text-center font-weight-normal">
                        {{ data.item.email }}
                    </small>
                </template>
                <template #cell(phone)="data">
                    <small class="text-center font-weight-normal">
                        {{ data.item.phone }}
                    </small>
                </template>
                <template #cell(updatedAt)="data">
                    <small class="text-center font-weight-normal">
                        {{ data.item.updatedAt | date }}
                    </small>
                </template>
            </b-table>
            <div v-if="!itemsCurrent.length" class="col-11 mx-auto mt-4">
                <strong>
                    Nuestro sistema NO encuentra resultados. Intenta modificar
                    los filtros de búsqueda.
                </strong>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

import CustomersFilters from "@/components/customers/CustomersFilters.vue";
import TooltipButton from "@/components/utils/TooltipButton.vue";
import TooltipRedirect from "@/components/utils/TooltipRedirect.vue";

export default {
    name: "IndexCustomers",
    components: { CustomersFilters, TooltipRedirect, TooltipButton },
    data: () => ({
        loaderInstance: null,
        itemsCurrent: [],
        selected: [],
        showTable: false,
        currentPage: 1,
        pagesNumber: 0,
        itemsNumber: 0,
        $fields: [
            { key: "id", label: "ID", sortable: false },
            {
                key: "identificationNumber",
                label: "Identificación",
                sortable: false
            },

            {
                key: "firstName",
                label: "Nombres",
                sortable: false
            },
            {
                key: "email",
                label: "Correo",
                sortable: false
            },
            {
                key: "phone",
                label: "Célular",
                sortable: false
            },
            { key: "updatedAt", label: "Actualización", sortable: true }
        ],
        query: {}
    }),
    computed: {
        ...mapState("control", ["activeDark"]),
        ...mapGetters("control", ["backgroundColor"])
    },
    watch: {},
    async mounted() {
        const verifyPageScope = this.$ability.verifyPageScope.bind(this);
        if (!(await verifyPageScope("customer:List", "/app"))) return;
        this.listItems(true);
    },
    methods: {
        ...mapActions("customers", ["listCustomersPaginated"]),
        async listItems(complete = false) {
            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({ type: "radius" });
            try {
                const { items, pagination } = await this.listCustomersPaginated(
                    {
                        ...this.query,
                        complete,
                        page: this.currentPage
                    }
                );
                this.itemsCurrent = items;
                if (pagination) {
                    this.itemsNumber = pagination.itemsNumber;
                    this.pagesNumber = pagination.pagesNumber;
                }
                this.showTable = true;
            } catch (error) {
                this.loaderInstance.close();
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.loaderInstance.close();
            }
        },
        async reloadItems() {
            this.currentPage = 1;
            await this.listItems(true);
        },
        onRowSelected(items) {
            this.selected = items;
        },
        clearSelected() {
            this.$refs.selectableTable.clearSelected();
            this.selected = [];
        }
    }
};
</script>
